// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Resources ajax filter
   */
  const orbitSpinner = $('.orbit-spinner');
  /* global ajax_object */
  $('.filter-button').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);
    if ($this.data('requestRunning')) {
      return;
    }
    $this.data('requestRunning', true);

    let filterCategory = $this.parents('.filter-wrap').find('.category-select');
    let categotySlug = filterCategory.find('option:selected').attr('data-slug');
    let categotyID = filterCategory.find('option:selected').attr('data-id');
    let categotyName = filterCategory.find('option:selected').attr('data-name');

    let filterTag = $this.parents('.filter-wrap').find('.tag-select');
    let tagSlug = filterTag.find('option:selected').attr('data-slug');
    let tagName = filterTag.find('option:selected').attr('data-name');

    var data = {
      action: 'filter',
      nonce: ajax_object.nonce,
      category_slug: categotySlug,
      category_id: categotyID,
      tag_slug: tagSlug,
      category_name: categotyName,
      tag_name: tagName,
    };
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: data,
      beforeSend: function () {
        orbitSpinner.attr('style', 'display:block');
      },
      success: function (result) {
        $('.resources-list').html(result.html);
        $('.reset-button').attr('style', 'display:block');
        orbitSpinner.attr('style', 'display:none');
      },
      complete: function () {
        $this.data('requestRunning', false);
      },
      error: function (err) {
        console.log(err);
      },
    });
  });

  /**
   * Staff ajax load more
   */
  const loadMoreCallBack = (e) => {
    e.preventDefault();
    var button = $('.loadMoreStaff-link'),
      paged = button.data('paged'),
      region = button.data('region'),
      IDregion = button.data('region_id'),
      maxPages = button.data('max_pages'),
      transportations = button.data('transportations'),
      city = button.data('city'),
      category = button.data('category');
    paged++;

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        region: region,
        IDregion: IDregion,
        transportations: transportations,
        city: city,
        category: category,
        provider_paged: paged,
        action: 'providerLoadMore',
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (data) {
        $('#provider-list').append(data);
        button.text('Load More');

        if (paged == maxPages) {
          button.hide();
        }
        button.data('paged', paged++);
      },
    });
  };

  $(document).on('click', '.loadMoreStaff-link', loadMoreCallBack);

  /**
   * Provider ajax filter
   */
  $('.provider-filter-button').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);
    if ($this.data('requestRunning')) {
      return;
    }
    $this.data('requestRunning', true);

    var data = {
      action: 'provider_filter',
      nonce: ajax_object.nonce,
      transportation_slug: $('#transportation-metod')
        .find(':selected')
        .data('slug'),
      transportation_name: $('#transportation-metod')
        .find(':selected')
        .data('name'),
      region_slug: $('#region').find(':selected').data('slug'),
      region_id: $('#region').find(':selected').data('id'),
      region_name: $('#region').find(':selected').data('name'),
      tag_slug: $('#tag').find(':selected').data('slug'),
      tag_name: $('#tag').find(':selected').data('name'),
      category_slug: $('#category').find(':selected').data('slug'),
      category_name: $('#category').find(':selected').data('name'),
    };
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: data,
      beforeSend: function () {
        orbitSpinner.attr('style', 'display:block');
      },
      success: function (result) {
        $('.resources-list').html(result.html);
        $('.reset-button').attr('style', 'display:block');
        orbitSpinner.attr('style', 'display:none');
      },
      complete: function () {
        $this.data('requestRunning', false);
      },
      error: function (err) {
        console.log(err);
      },
    });
  });

  /**
   * Get tags by region
   */

  $('.js-region-select').on('change', function () {
    const regionId = $(this).find(':selected').data('id');
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'get_region_tags',
        nonce: ajax_object.nonce,
        region_id: regionId,
      },
      success: function (response) {
        $('.js-tag-select').html(response.html);
      },
      error: function (err) {
        console.error(err);
      },
    });
  });

  /**
   * Regions map
   */
  $('.regions__item').hover(
    function () {
      var regionMapClass = '.' + $(this).data('map');
      var regionAnimateClass = $(this).data('map') + '-animate';
      $(regionMapClass).addClass(regionAnimateClass);
    },
    function () {
      var regionMapClass = '.' + $(this).data('map');
      var regionAnimateClass = $(this).data('map') + '-animate';
      $(regionMapClass).removeClass(regionAnimateClass);
    }
  );

  /**
   * Hero Slider
   */
  $('#home-slider').slick({
    cssEase: 'ease',
    fade: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    slide: '.slick-slide',
  });
  /**
   * Top button
   */
  let offset = 300;
  let speed = 350;
  let duration = 1000;
  $(window).scroll(function () {
    if ($(this).scrollTop() < offset) {
      $('.topbutton').fadeOut(duration);
    } else {
      $('.topbutton').fadeIn(duration);
    }
  });
  $('.topbutton').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, speed);
    return false;
  });
  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
